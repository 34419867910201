@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 900;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-ExtraBold.otf") format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Bold.otf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-SemiBold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 200;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 100;
}

.callwise-wrapper {
    position: relative;
    margin-top: 19.5%;
    display: flex;
    width: 100%;
}

.bg-callwise {
    height: 1290px;
    Z-INDEX: 11;
    overflow: hidden;
    width: 100%;
    position: relative;
}

.callwisebg {
    top: 9.5%;
    position: absolute;
    width: 100%;
}

.callwise-left,
.callwise-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.callwise-left {
    padding-left: 8%;
}

.callwise-right {
    margin-top: 6%;
}

.bullets {
    display: flex;
    align-items: center;
    gap: 1%;
}

.callwise-left h1 {
    font-family: 'Trap';
    font-size: 73.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 120.336%;
    /* 88.447px */
    letter-spacing: -2.572px;
    background: linear-gradient(180deg, #6F6ED0 0%, #4B4AA8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.callwise-left p {
    width: 460px;
    color: #FFF;
    font-family: 'Trap';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 159.836%;
    /* 25.574px */
    letter-spacing: -0.56px;
}

.bullets p {
    color: #FFF;
    font-family: 'Trap';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 159.836%;
    /* 25.574px */
    letter-spacing: -0.96px;
}

.callwise-bg {
    position: absolute;
    opacity: 0.05;
    width: 80%;
    animation: movement 50s linear infinite;
}

@keyframes movement {
    0% {
        transform: translateX(-10%);
    }

    50% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-10%);
    }
}

.callwisebgright-bg {
    position: absolute;
    top: -30%;
    width: 60%;
    left: 3%;
    animation: rotate 15s infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(-90deg);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-90deg);
    }
}

.gradient {
    position: absolute;
    background: linear-gradient(rgba(84, 60, 174, 1), rgba(49, 24, 119, 1));
    width: 230px;
    height: 600px;
    opacity: 0.4;
    filter: blur(160px);
    left: 63%;
    top: 11%;
    rotate: -45deg;
}

.play-btn-wrapper {
    background: white;
    filter: drop-shadow(0px 61px 25px rgba(45, 6, 7, 0.01)) drop-shadow(0px 35px 21px rgba(45, 6, 7, 0.05)) drop-shadow(0px 15px 15px rgba(45, 6, 7, 0.09)) drop-shadow(0px 4px 8px rgba(45, 6, 7, 0.1)) drop-shadow(0px 0px 0px rgba(45, 6, 7, 0.1));
    border-radius: 8px;
    padding: 12px 34px;
    width: fit-content;
    margin-bottom: 30px;
}

.play-btn-wrapper p {
    color: #000;
    font-family: 'Trap';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 9px;
}

.ply-btn-bg {
    background: rgba(49, 24, 119, 1);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ply-btn-bg:hover {
    cursor: pointer;
}

.flex-play-btn-l-d {
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all .5s ease-out;
}

.play-btn-lines-main {
    display: flex;
    gap: 3px;
    align-items: center;
}

.play-btn-lines {
    background: rgba(49, 24, 119, 1);
    width: 5px;
    border-radius: 34px;

}

.play-btn-lines.active {
    background: black;
}

.flex-play-btn-l-d.active .play-btn-lines.inactive {
    background: rgba(0, 0, 0, 0.1)
}

.line1 {
    height: 7px;
}

.line2 {
    height: 14px;
}

.line3 {
    height: 21px;
}

.line4 {
    height: 14px;
}

.line5 {
    height: 7px;
}

.line6 {
    height: 7px;
}

.line7 {
    height: 14px;
}

.line8 {
    height: 21px;
}

.line9 {
    height: 14px;
}

.line10 {
    height: 7px;
}

.line11 {
    height: 7px;
}

.line12 {
    height: 14px;
}

.line13 {
    height: 21px;
}

.line14 {
    height: 14px;
}

.line15 {
    height: 7px;
}

.line16 {
    height: 7px;
}

.line17 {
    height: 14px;
}

.line18 {
    height: 21px;
}

.line19 {
    height: 14px;
}

.line20 {
    height: 7px;
}

.btn-play-wise.active {
    background: #FFFFFF;
    color: black;


}

.btn-play-wise {
    background: black;
    box-shadow: 0px 78px 31px rgba(0, 0, 0, 0.01), 0px 44px 26px rgba(0, 0, 0, 0.05), 0px 19px 19px rgba(0, 0, 0, 0.09), 0px 5px 11px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: none;
    height: 46px;
    width: 170px;
    text-transform: uppercase;
    font-weight: 500;
    color: white;
    font-size: 16px;
}

.play-wise-btn-flex {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
}

.playroom-flex-right-f-p {
    font-size: 18px;

    text-transform: capitalize;

    color: #FFFFFF;
    margin-bottom: 40px;
}

.list-playroom li {
    list-style: disc;
    font-size: 16px;

    text-transform: capitalize;

    color: #FFFFFF;
    margin-bottom: 10px;
}

.list-playroom {
    padding: 0 20px;
}

.l-p-playroom {
    font-weight: 600;
    font-size: 14px;

    text-transform: capitalize;

    color: #FFFFFF;
    margin-top: 60px;
}

.call-wrapper-playroom {
    background: white;
    width: fit-content;
    padding: 5px 10px 5px 5px;
    border-radius: 30px;
}

.playwise-m-img-call-w {
    background: #E31E25;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.call-img-wrapper-playroom {
    display: flex;
    align-items: center;
    gap: 10px;
}

.call-img-wrapper-playroom p {
    color: #E31E25;
    margin-bottom: 0;
    font-weight: 600;
}

.web-d-none-btn {
    display: none;
}

.flex-play-btn-l-d.active {
    transform: scale(1.1);
}

.flex-play-btn-l-d.active .ply-btn-bg {
    background: rgba(49, 24, 119, 1);
}

.callwise-bg {
    position: absolute;
    opacity: 0.05;
    width: 120%;
    top: 40%;
    animation: movement 50s linear infinite;
}

.callwise-button {
    margin-top: 50px;
    width: fit-content;
    padding: 10px 30px;
    font-size: 14px;
    background: linear-gradient(rgba(62, 38, 142, 1), rgba(81, 56, 175, 1));
    color: white;
    border-radius: 20px;
    border: 2px solid #4B4AA8;
    display: flex;
    justify-content: center;
    gap: 7px;
    font-family: 'Trap';

}

@keyframes movement {
    0% {
        transform: translateX(-20%);
    }

    50% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-20%);
    }
}

@media (max-width: 1584px) {
    .callwise-wrapper {
        margin-top: 16.5%;
    }

    .callwisebg {
        top: 2.5%
    }

    .callwisebgright-bg {
        position: absolute;
        top: -21%;
        width: 64%;
        left: 10%;
        animation: rotate 15s infinite;
    }

    .callwise-bg {
        top: 20%;
    }

    .bg-callwise {
        height: 1088px;
    }
}

@media (max-width: 1440px) {
    .callwisebgright-bg {
        position: absolute;
        top: -19%;
        width: 69%;
        left: 10%;
        animation: rotate 15s infinite;
    }
}

@media (max-width:1366px) {

    .bg-callwise {
        height: 900px;
        overflow: hidden;
        width: 100%;
        position: relative;
    }

    .callwisebgright-bg {
        top: -19%;
        left: 8%;
        width: 75%;
    }

    .callwisebg {
        top: -18.5%;
        position: absolute;
        width: 100%;
    }

    .gradient {
        left: 77%;
        top: 5%;
        width: 115px;
        height: 279px;
    }

    .callwise-bg {
        position: absolute;
        opacity: 0.05;
        width: 120%;
        top: 17%;
        animation: movement 50s linear infinite;
    }

    @keyframes movement {
        0% {
            transform: translateX(-20%);
        }

        50% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(-20%);
        }
    }

    .callwise-wrapper {
        margin-top: 14.5%;
    }
}

@media (max-width: 1024px) {
    .callwisebgright-bg {
        top: -6%;
        left: 22%;
        width: 76%;
    }

    .callwise-right {
        margin-left: 5%;
    }
}

@media (max-width:820px) {
    .callwise-wrapper {
        margin-top: 28.5%;
    }

    .callwise-left h1 {
        font-size: 33.27px;
    }

    .callwise-left p {
        width: 300px;
        font-size: 14.14px;
    }

    .bullets {
        width: 248px;
        gap: 11px;
    }

    .bullets p {
        font-size: 14.14px;
    }

    .callwise-right {
        width: 218px;
        padding-left: 32px;
        margin-top: 9%;
    }

    .bg-callwise {
        height: 776px;
    }

    .callwise-bg {
        height: 392px;
        top: 25%;
        width: 200%;
        left: -15%;
    }

    .callwisebgright-bg {
        top: -28%;
        left: 45%;
        width: 250%;
    }

    .callwise-right {
        margin-left: 0%;
    }

    @keyframes movement {
        0% {
            transform: translateX(-20%);
        }

        50% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(-20%);
        }
    }
}

@media (max-width:490px) {
    .callwise-wrapper {
        flex-direction: column;
    }

    .callwise-left h1 {
        font-size: 33.27px;
    }

    .callwise-left p {
        width: 270px;
        font-size: 14.14px;
    }

    .bullets {
        width: 248px;
        gap: 11px;
    }

    .bullets p {
        font-size: 14.14px;
    }

    .callwise-right {
        width: 218px;
        padding-left: 32px;
        margin-top: 11%;
    }

    .bg-callwise {
        height: 1120px;
    }

    .callwise-bg {
        height: 392px;
        top: 15%;
        width: 200%;
        left: -15%;
    }

    .callwisebgright-bg {
        top: -28%;
        left: 87%;
        width: 250%;
    }

    @keyframes movement {
        0% {
            transform: translateX(-20%);
        }

        50% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(-20%);
        }
    }
}

@media (max-width:450px) {
    .callwise-wrapper {
        flex-direction: column;
    }

    .callwise-left h1 {
        font-size: 33.27px;
    }

    .callwise-left p {
        width: 270px;
        font-size: 14.14px;
    }

    .bullets {
        width: 248px;
        gap: 11px;
    }

    .bullets p {
        font-size: 14.14px;
    }

    .callwise-right {
        width: 218px;
        padding-left: 32px;
        margin-top: 30%;
    }

    .bg-callwise {
        height: 1139px;
    }

    .callwise-bg {
        height: 392px;
        top: 15%;
        width: 200%;
        left: -15%;
    }

    .callwisebgright-bg {
        top: -28%;
        left: 54%;
        width: 250%;
    }

    @keyframes movement {
        0% {
            transform: translateX(-20%);
        }

        50% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(-20%);
        }
    }
}

@media (max-width:430px) {
    .callwisebgright-bg {
        top: -28%;
        left: 72%;
        width: 250%;
    }

    .callwise-bg {
        opacity: 0.1;
    }

    .bg-callwise {
        height: 1236px;
    }
}

@media (max-width:428px) {
    .callwise-left p {
        width: 350px;
    }
}

@media (max-width: 414px) {
    .callwisebgright-bg {
        top: -28%;
        left: 55%;
        width: 250%;
    }
}

@media (max-width:412px) {
    .callwisebgright-bg {
        left: 55%;
    }
}

@media (max-width:393px) {
    .callwisebgright-bg {
        left: 55%;
    }
}

@media (max-width:390px) {
    .callwisebgright-bg {
        top: -28%;
        left: 55%;
        width: 250%;
    }
}

@media (max-width:375px) {
    .callwise-left p {
        width: 320px;
    }

    .callwisebgright-bg {
        left: 47%;
    }

    .callwise-bg {
        top: 8%;
    }
}

@media (max-width:360px) {
    .callwisebgright-bg {
        left: 41%;
    }

    .callwise-wrapper {
        margin-top: 26.5%;
        gap: 36px;
    }

    .callwise-left p {
        width: 290px;
    }

}

@media (max-width:280px) {
    .callwise-left {
        padding-left: 5%;
    }

    .callwise-left p {
        width: 234px;
    }

    .play-btn-wrapper {
        background: white;
        filter: drop-shadow(0px 61px 25px rgba(45, 6, 7, 0.01)) drop-shadow(0px 35px 21px rgba(45, 6, 7, 0.05)) drop-shadow(0px 15px 15px rgba(45, 6, 7, 0.09)) drop-shadow(0px 4px 8px rgba(45, 6, 7, 0.1)) drop-shadow(0px 0px 0px rgba(45, 6, 7, 0.1));
        border-radius: 8px;
        padding: 11px 16px;
        width: -moz-fit-content;
        width: fit-content;
        margin-bottom: 30px;
    }

    .callwise-right {
        padding-left: 10px;
    }

    .callwisebgright-bg {
        left: 3%;
    }
}