.testimonial-main {
    position: relative;
    z-index: 6;
    display: flex;
    padding-left: 8%;
    padding-top: 5%;
    gap: 14%;
    padding-bottom: 5%;
    background: url(../../images/testimonialbackground.png)
}

.testpatternbg {
    position: absolute;
    right: 31%;
}

.testimonial-left {
    display: flex;
    flex-direction: column;
}

.leftcontent h1 {
    font-family: 'Trap';
    font-size: 73.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 102.336%;
    letter-spacing: -2.572px;
    background: linear-gradient(180deg, #6F6ED0 0%, #4B4AA8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.leftcontent .bottomline {
    margin-top: -13%;
    padding-bottom: 21px;
}

.leftcontent h2 {
    color: #FFF;
    font-family: 'Trap';
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 93.336%;
    /* 39.201px */
    letter-spacing: -2.52px;
}

.leftcontent p {
    color: #FFF;
    font-family: 'Trap';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 131.2%;
    width: 90%;
    padding-top: 36px;
    letter-spacing: -0.4px;
}

.leftcontent .arrows {
    padding-top: 4%;
}

.testimonial-right {
    position: relative;
    width: 458px;
    height: 345px;
    z-index: 3;
    margin-left: 14%;
    display: flex;
    border-radius: 30px;
    border: 1px solid #7F7EE0;
    background: linear-gradient(270deg, #4828A1 16.74%, #3C2285 84.13%);
    box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.10), 0px 32px 32px 0px rgba(0, 0, 0, 0.09), 0px 71px 43px 0px rgba(0, 0, 0, 0.05), 0px 127px 51px 0px rgba(0, 0, 0, 0.01), 0px 198px 55px 0px rgba(0, 0, 0, 0.00);
}

.rightbg {
    position: absolute;
    right: 0%;
    opacity: 0.04;
    width: 458px;
    height: 345px;
    border-radius: 30px;
}

.right-content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 411px;
    padding: 29px 50px;
}

.content-heading {
    display: flex;
    align-items: center;
    gap: 8px;
}

.content-heading h1 {
    color: #FFF;
    font-family: 'Trap';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 131.2%;
    padding-top: 10px;
    letter-spacing: -0.12px;
}

.review {
    padding-top: 21px;
}

.review-p {
    color: #FFF;
    font-family: 'Trap';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 131.2%;
    letter-spacing: -0.4px;
}

.reviewstar {
    padding-top: 10px;
    margin-left: 52px;
}

.testimonial-gradient {
    position: absolute;
    background: linear-gradient(180deg, rgba(84, 60, 174, 0.30) 0%, rgba(49, 24, 119, 0.30) 64.9%);
    width: 247.025px;
    height: 720.889px;
    transform: rotate(64.284deg);
    opacity: 0.5;
    filter: blur(108px);
    left: 9%;
    top: -28%;
}

.arrow-next,
.arrow-prev {
    position: absolute;
    color: white;
    z-index: 99999;
    padding: 10px 15px;
    scale: 1.1;
    bottom: -14%;
    cursor: pointer;

}

.arrow-next {
    left: -989px;
    background: linear-gradient(rgba(111, 110, 208, 1), rgba(49, 24, 119, 1));
}

.arrow-prev {
    left: -1039px;
}

.testimonials-App {
    position: relative;
    z-index: 3;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

@media (max-width: 1680px) {
    .arrow-next {
        left: -935px;
    }

    .arrow-prev {
        left: -989px;
    }
}

@media (max-width: 1584px) {
    .testimonial-main {
        gap: 0;
    }

    .arrow-next {
        left: -704px;
        background: linear-gradient(rgba(111, 110, 208, 1), rgba(49, 24, 119, 1));
    }

    .arrow-prev {
        left: -753px;
    }

    .testpatternbg {
        right: 0;
    }

}

@media (max-width: 1366px) {
    .testpatternbg {
        right: 35%;
    }

    .testimonial-right {
        margin-left: 7%;
    }

    .arrow-next {
        left: -598px;
        background: linear-gradient(rgba(111, 110, 208, 1), rgba(49, 24, 119, 1));
    }

    .arrow-prev {
        left: -648px;
    }

    .testimonial-main {
        z-index: 6;
        padding-bottom: 5%;
    }
}

@media (max-width:1024px) {
    .testimonial-right {
        margin-left: 2%;
    }

    .arrow-next {
        left: -499px;
        background: linear-gradient(rgba(111, 110, 208, 1), rgba(49, 24, 119, 1));
    }

    .arrow-prev {
        left: -555px;
    }

    .testimonial-right {
        width: 387px;

    }

    .right-content {
        padding: 29px 33px;
    }
}

@media (max-width:820px) {
    .testimonial-main {
        margin-top: 10%;
        flex-direction: row;
        padding-bottom: 26%;
    }

    .leftcontent h1 {
        font-size: 33.27px;
    }

    .leftcontent .bottomline {
        margin-top: -12%;
        width: 206.285px;
    }

    .leftcontent h2 {
        font-size: 21.608px;
        letter-spacing: -1.08px
    }

    .leftcontent p {
        padding-top: 0;
        font-size: 14.14px;
        width: 291.02px;
    }

    .testimonial-left {
        padding-bottom: 5%;
    }

    .testimonial-right {
        margin-left: 10%;
    }

    .arrow-prev,
    .arrow-next {
        bottom: -64%;
    }

    .arrow-next {
        left: -18%;
    }

    .arrow-prev {
        left: -33%;
        background: linear-gradient(180deg, rgba(111, 110, 208, 0.3) 0%, rgba(49, 24, 119, 0.3) 100%);
    }

    .rightbg {
        width: 327.331px;
        height: 251.573px;
        right: 28%;
    }

    .testimonial-right {
        width: 327.331px;
        height: 251.573px;
    }

    .content-heading h1 {
        font-size: 21.38px;
    }

    .review-p {
        font-size: 14.14px;
        width: 284.175px;
    }

    .reviewstar {
        margin-left: 21px;
    }

    .review {
        padding-top: 20px;
    }

    .right-content {
        padding: 0px 26px;
    }

    .testimonial-gradient {
        display: none;
    }
    .testpatternbg{
        display: none;
    }
}

@media (max-width: 490px) {
    .testimonial-main {
        margin-top: 5%;
        flex-direction: column;
        padding-bottom: 30%;
    }

    .leftcontent h1 {
        font-size: 33.27px;
    }

    .leftcontent .bottomline {
        margin-top: -12%;
        width: 206.285px;
    }

    .leftcontent h2 {
        font-size: 21.608px;
        letter-spacing: -1.08px
    }

    .leftcontent p {
        padding-top: 0;
        font-size: 14.14px;
        width: 291.02px;
    }

    .testimonial-left {
        padding-bottom: 5%;
    }

    .testimonial-right {
        width: 327.331px;
        height: 251.573px;
        margin-left: 8%;
    }

    .arrow-prev,
    .arrow-next {
        bottom: -64%;
    }

    .arrow-next {
        left: 48%;
    }

    .arrow-prev {
        left: 33%;
        background: linear-gradient(180deg, rgba(111, 110, 208, 0.3) 0%, rgba(49, 24, 119, 0.3) 100%);
    }

    .rightbg {
        width: 327.331px;
        height: 251.573px;
        right: 28%;
    }

    .content-heading h1 {
        font-size: 21.38px;
    }

    .review-p {
        font-size: 14.14px;
        width: 284.175px;
    }

    .reviewstar {
        margin-left: 21px;
    }


    .right-content {
        padding: 0px 26px;
    }
}

@media (max-width: 450px) {
    .testimonial-main {
        margin-top: 25%;
        flex-direction: column;
        padding-bottom: 60%;
    }

    .leftcontent h1 {
        font-size: 33.27px;
    }

    .leftcontent .bottomline {
        margin-top: -12%;
        width: 206.285px;
    }

    .leftcontent h2 {
        font-size: 21.608px;
        letter-spacing: -1.08px
    }

    .leftcontent p {
        padding-top: 0;
        font-size: 14.14px;
        width: 291.02px;
    }

    .testimonial-left {
        padding-bottom: 5%;
    }

    .testimonial-right {
        margin-left: 0;
    }

    .arrow-prev,
    .arrow-next {
        bottom: -64%;
    }

    .arrow-next {
        left: 48%;
    }

    .arrow-prev {
        left: 33%;
        background: linear-gradient(180deg, rgba(111, 110, 208, 0.3) 0%, rgba(49, 24, 119, 0.3) 100%);
    }

    .rightbg {
        width: 327.331px;
        height: 251.573px;
        right: 0%;
    }

    .testimonial-right {
        width: 327.331px;
        height: 251.573px;
    }

    .content-heading h1 {
        font-size: 21.38px;
    }

    .review-p {
        font-size: 14.14px;
        width: 284.175px;
    }

    .reviewstar {
        margin-left: 21px;
    }


    .right-content {
        padding: 0px 26px;
    }
}

@media (max-width:430px) {
    .arrow-next {
        left: 49.5%;
    }

    .arrow-prev {
        left: 34%;
    }

    .testimonial-right {
        margin-left: 18px
    }
}

@media (max-width:393px) {
    .testimonial-right {
        margin-left: 0px;
    }
}

@media (max-width:360px) {
    .testimonial-right {
        width: 306.331px;
        height: 251.573px;
        margin-left: 0;
    }

    .review-p {
        font-size: 13.14px;
        width: 266.175px;
    }

    .content-heading {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}

@media (max-width:280px) {
    .leftcontent p {
        padding-top: 0;
        font-size: 14.14px;
        width: 217.01999999999998px;
    }

    .review-p {
        font-size: 13.14px;
        width: 220.175px;
    }

    .testimonial-right {
        width: 249.33100000000002px;
        height: 286.573px;
        margin-left: 0;
    }

    .right-content {
        padding: 0px 13px;
    }

    .reviewstar {
        margin-left: -4px;
        scale: 0.7;
    }

    .arrow-prev {
        left: 29%;
    }

    .testimonial-main {
        padding-left: 5%;
    }
}