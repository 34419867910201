@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 900;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-ExtraBold.otf") format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Bold.otf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-SemiBold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 200;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 100;
}

.contact-wrapper {
    margin-top: 15%;
    display: flex;
    position: relative;
    z-index: 9;
}

.contact-wrapper-bg {
    position: absolute;
    width: 100%;
    top: -179%;
}

.bottom-bar {
    background: #151515;
    display: flex;
    gap: 40px;
    position: relative;
    z-index: 6;
    padding-left: 8%;
    height: 75px;
    align-items: center;
}

.bottom-bar a {
    color: rgba(255, 255, 255, 0.60);
    font-family: 'Trap';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 128.7%;
    /* 19.305px */
    letter-spacing: -0.375px;
    text-decoration: none;

}

.bottom-bar a:hover {
    text-decoration: none;
}

.inner-left,
.inner-right {
    width: 50%;
    position: relative;
}

.inner-right {
    overflow: hidden;

}

.inner-left {
    display: flex;
    flex-direction: column;
    padding-left: 8%;
    z-index: 5;
}

.inner-heading h1 {
    font-family: 'Trap';
    font-size: 73.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 97.336%;
    /* 62.722px */
    letter-spacing: -2.572px;
    background: linear-gradient(180deg, #6F6ED0 0%, #4B4AA8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.inner-heading p {
    color: #FFF;
    font-family: 'Trap';
    font-size: 23.294px;
    font-style: normal;
    font-weight: 400;
    line-height: 93.336%;
    /* 21.742px */
    letter-spacing: -1.398px;
}

.contact-info {
    display: flex;
    gap: 40px;
    padding: 104px 0px;
}

.-email,
.number {
    color: #FFF;
    font-family: 'Trap';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 128.7%;
    /* 20.592px */
    letter-spacing: -0.4px;
    padding-top: 9px;
}

.contact-info span {
    color: #424242;
    font-family: 'Trap';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 128.7%;
    /* 16.731px */
    letter-spacing: -0.325px;
    padding-left: 6px;
}

.address {
    color: #FFF;
    font-family: 'Trap';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 128.7%;
    /* 20.592px */
    letter-spacing: -0.4px;
    width: 50%;
    padding-bottom: 65px;
}

form {
    padding-top: 6%;
    display: flex;
    flex-direction: column;
    gap: 78px;
}

.form-email,
.form-message {
    display: flex;
    flex-direction: column;
    width: 60%;
    z-index: 1;
}


label {
    color: #FFF;
    font-family: 'Trap';
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 128.7%;
    /* 23.166px */
    letter-spacing: -0.45px;
}

input {
    color: white;
    background: none;
    border-width: 0px;
    border-bottom: 1px solid #5F5F5F !important;
    margin-top: 35px;
}

form button {
    display: flex;
    width: 95.953px;
    height: 30.594px;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: #FFF;
    font-family: 'Trap';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 128.7%;
    /* 20.592px */
    letter-spacing: -0.4px;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.10);
    border: 1px solid rgba(82, 50, 168, 1);
}

.pattern-contact {
    position: absolute;
    right: 25%;
    scale: 1;
    bottom: 5%;
    rotate: 180deg;
    opacity: 0.4;
    z-index: -1;
}

.gradient-contact {
    position: absolute;
    background: linear-gradient(rgba(84, 60, 174, 1), rgba(49, 24, 119, 1));
    width: 98.874px;
    height: 757.911px;
    filter: blur(160px);
    right: 30%;
    top: -26%;
    rotate: 90deg;
    animation: glowingmovement 3s infinite ease-in-out;
}

.error {
    color: white;
}

.thankyoumessage {

    width: 450px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 43px 99px;
}

.thankyoumessage img {
    width: 72px;
    height: 72px;
}

.thankyouwrapper {
    display: flex;
    justify-content: center;
    padding-top: 13%;
}

.thankyoumessage h1 {
    margin-top: 23px;
    color: #FFF;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    line-height: 85.523%;
    letter-spacing: -0.475px;
}

.thankyoumessage p {
    margin-top: 0;
}

input:focus-visible {
    outline: black;
}

@keyframes glowingmovement {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }

}

@media (max-width: 1584px) {
    .contact-wrapper-bg {
        top: -125%;
    }

    .thankyouwrapper {
        display: flex;
        justify-content: center;
        padding-top: 13%;
    }
}

@media (max-width:1366px) {
    .contact-wrapper-bg {
        top: -101%;
    }
}

@media (max-width:820px) {
    .pattern-contact {
        display: none;
    }

    .inner-heading h1 {
        font-size: 45.693px;
    }

    .inner-heading p {
        font-size: 14.14px;
        letter-spacing: -0.398px;
        margin-bottom: 0;
    }

    .contact-wrapper {
        flex-direction: column;
        padding-bottom: 15%;
        margin-top: 15%;
    }

    .inner-left {
        width: fit-content;
    }

    .contact-info {
        padding: 80px 0px;
    }

    .address {
        width: 80%;
    }

    .inner-right {
        padding-left: 32px;
        width: 100%;
    }

    .form-email,
    .form-message {
        width: 80%;
    }

    .bottom-bar a {
        font-size: 9.719px;
    }

    form {
        padding-left: 5%;
    }

    .bottom-bar {
        gap: 70px;
    }

    .gradient-contact {
        position: absolute;
        background: linear-gradient(rgba(84, 60, 174, 1), rgba(49, 24, 119, 1));
        width: 98.874px;
        height: 277.91099999999994px;
        filter: blur(160px);
        left: 0;
        top: 54%;
        rotate: 90deg;
        animation: glowingmovement 3s infinite ease-in-out;
    }
}

@media (max-width:450px) {
    .inner-heading h1 {
        font-size: 45.693px;
    }

    .inner-heading p {
        font-size: 14.14px;
        letter-spacing: -0.398px;
        margin-bottom: 0;
    }

    .contact-wrapper {
        flex-direction: column;
        padding-bottom: 15%;
        margin-top: 28%;
    }

    .inner-left {
        width: fit-content;
    }

    .contact-info {
        padding: 80px 0px;
    }

    .address {
        width: 80%;
    }

    .inner-right {
        padding-left: 32px;
        width: 100%;
    }

    .form-email,
    .form-message {
        width: 80%;
    }

    .bottom-bar a {
        font-size: 9.719px;
    }

    form {
        padding-left: 0;
    }
}

@media (max-width:280px) {
    .inner-left {
        padding-left: 5%;
    }

    .-email,
    .number {
        font-size: 13px;
    }
}