@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@400;500;800&family=Varela+Round&display=swap');

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 900;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-ExtraBold.otf") format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Bold.otf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-SemiBold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 200;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 100;
}

.bg-body-tertiary {
    background: linear-gradient(rgba(62, 38, 142, 1), rgba(82, 56, 176, 1)) !important;
    width: 90%;
    height: 48px;
    display: flex;
    margin: auto;
    border-radius: 200px;
    margin-top: 66px;
    z-index: 6;
}

.navbar-brand {
    margin-left: 18px;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-menu img {
    width: 18px;
    margin-right: 10px;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: white;
    background: none;
}

.navbar-collapse {
    justify-content: flex-end;
}

.navbar-nav {
    gap: 17px;
    margin-right: 59px;
}

.nav-link,
.nav-link.active {
    padding: 10px;
    color: #FFF !important;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
    align-items: baseline !important;
    justify-content: center;
}

.dropdown-menu {
    padding: 11px;
    border-radius: 8.72px;
    background-color: rgba(111, 110, 208, 0.16);
}

.navbar-nav .dropdownmain {
    z-index: 5;
    padding: 10px 0px;
    background: linear-gradient(rgba(62, 38, 142, 1), rgba(81, 56, 175, 1));
    border-radius: 8.72px;
    border: 1px solid linear-gradient(rgba(111, 110, 208, 0.6));
}

.navbar-nav .dropdownmain::before {
    content: "";
    position: absolute;
    top: 9px;
    left: 9px;
    right: 0;
    border-radius: 8.72px;
    bottom: 0;
    border: 1px solid transparent;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(81, 56, 175, 1));
    background-clip: padding-box;
    z-index: -1;
    width: 213.28px;
    height: 260.98px;
}

.dropdown-item {
    letter-spacing: 0.6px;
    border-bottom: 1px solid white;
    padding: 16px;
    color: #FFF;
    font-family: 'Trap';
    font-size: 14.041px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dropdown-menu #ARnav {
    border-bottom: none !important;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-bottom: 0px;
}

.dropdown-menu #callwisenav {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-top: 10px;
}

.mobile-nav {
    display: none;
}
@media (max-width:820px) {
    .navbar {
        display: none;
    }

    .mobile-nav {
        display: block;
        position: relative;
        z-index: 5;
    }

    .header-navbar {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-left: 33%;
        gap: 45%;
    }

    .navheader-image {
        width: fit-content;
        padding: 9px 40px;
        background-image: url(../../images/navbarbrand-bg.svg);
    }

    .navmenu-image {
        position: absolute;
        width: fit-content;
        padding: 9px 44px;
        bottom: -17%;
        background-image: url(../../images/navbarbrand-bg.svg);
    }

    .menu-navbar {
        width: 100%;
        background: linear-gradient(rgba(49, 24, 119, 1), rgba(81, 56, 175, 1));
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        z-index: 5;
        transition: all 400ms ease-in-out;
        position: absolute;
        border-radius: 0px 0px 40px 40px;
        padding-bottom: 40px;
    }

    .navbarcross {
        align-self: end;
        padding: 5px 11px;
    }

    .dropdownitems {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .discoveries-dropdown {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        gap: 20px;
    }

    .discoveries-dropdown a {
        color: rgba(255, 255, 255, 0.70);
        text-align: center;
        font-family: 'Inter';
        font-size: 12.344px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: none;
    }
}
@media (max-width:600px) {
    .navbar {
        display: none;
    }

    .mobile-nav {
        display: block;
        position: relative;
        z-index: 5;
    }

    .header-navbar {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-left: 16%;
        gap: 14%;
    }

    .navheader-image {
        width: fit-content;
        padding: 9px 40px;
        background-image: url(../../images/navbarbrand-bg.svg);
    }

    .navmenu-image {
        position: absolute;
        width: fit-content;
        padding: 9px 44px;
        bottom: -17%;
        background-image: url(../../images/navbarbrand-bg.svg);
    }

    .menu-navbar {
        width: 100%;
        background: linear-gradient(rgba(49, 24, 119, 1), rgba(81, 56, 175, 1));
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        z-index: 5;
        transition: all 400ms ease-in-out;
        position: absolute;
        border-radius: 0px 0px 40px 40px;
        padding-bottom: 40px;
    }

    .navbarcross {
        align-self: end;
        padding: 5px 11px;
    }

    .dropdownitems {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .discoveries-dropdown {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        gap: 20px;
    }

    .discoveries-dropdown a {
        color: rgba(255, 255, 255, 0.70);
        text-align: center;
        font-family: 'Inter';
        font-size: 12.344px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: none;
    }
    .bottom-bar {
        gap: 20px;
    }
}
@media (max-width:500px){
    .header-navbar {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-left: 24%;
        gap: 20%;
    }
}
@media (max-width:280px) {
    .header-navbar {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-left: 14%;
        gap: 0%;
    }
}