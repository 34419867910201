@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 900;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-ExtraBold.otf") format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Bold.otf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-SemiBold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Light.otf") format("opentype");
    font-weight: 200;
}

@font-face {
    font-family: "Trap";
    src: url("../../Fonts/Trap-Black.otf") format("opentype");
    font-weight: 100;
}

.scrollup {
    position: fixed;
    z-index: 99;
    padding: 5px;
    right: 5%;
    bottom: 5%;
    border-radius: 6px;
    display: flex;
    transform: rotate(180deg);
    scale: 2;
    cursor: pointer;
}

body {
    max-width: 100%;
    background-color: #181818;
    overflow-x: hidden;
    position: relative;
}

body::before {
    content: "";
    position: fixed;
    top: -1%;
    left: 0;
    height: 100%;
    opacity: 0.03;
    width: 100%;
    background-image: url(../../images/grid-background.svg);
    background-size: cover;
    z-index: 5;
}

.bullets img {
    padding-bottom: 4px;
}

.vectorI,
.vectorII,
.vectorIII {
    position: absolute;
    height: 17px;
    width: 1px;
    background: white;
    z-index: 999;
    top: 99%;
    opacity: 1;
}

.vectorI {
    left: 20%;

    animation: moveup 6s infinite 3s
}

.vectorII {
    left: 50%;
    animation: moveup 6s infinite 3.5s
}

.vectorIII {
    left: 80%;

    animation: moveup 6s infinite 3.7s
}

@keyframes moveup {
    0% {
        top: 99%;
    }

    100% {
        top: -10%;
    }
}

p {
    margin-top: 1rem;
}


.homebanner {
    background: #181818;
    position: relative;
    padding-bottom: 8%;
}

.homebanner video {
    position: absolute;
    width: 100%;
    opacity: 0.1;
    margin-top: -45%;
}

.bg {
    position: absolute;
    width: 100%;
    top: -15.5%;
}

.sectionII-bg {
    position: absolute;
    width: 100%;
    top: -24%;
}

.homepage-banner {
    position: relative;
    z-index: 1;
    padding-top: 10%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.homepage-banner h1,
.homepage-banner h2 {
    font-family: 'Trap';
    font-weight: 900;
    font-size: 54.334px;
    color: #ffff;
}

.heading-span {
    font-size: 71.128px;
}

.homepage-banner .heading-tagline {
    font-family: "Trap";
    font-size: 18px;
    font-weight: 300;
    color: #ffff;
    width: 70%;
    margin-top: 2%;
    margin-bottom: 0;
}

.homepage-banner .spacing-p {
    font-family: "Trap";
    font-size: 18px;
    font-weight: 300;
    color: #ffff;
    width: 70%;
    margin-top: 0;
}

.homepage-sectionII {
    margin-top: 15%;
    position: relative;
}

.homepage-sectionII .line {
    position: absolute;
    left: 61.5%;
    bottom: 85%;
}

.homepage-sectionII .lineb {
    position: absolute;
    left: 61.5%;
    bottom: -8%;
}

.homepage-sectionII .dots {
    position: absolute;
    left: 13%;
    bottom: -8%;
}

.homepage-sectionII .dots2 {
    position: absolute;
    left: 37.8%;
    top: 4%;

}

.homepage-sectionII .line-2 {
    position: absolute;
    left: 13.05%;
    bottom: 6%;

}

.homepage-sectionII .dotted {
    position: absolute;
    left: 14%;
    bottom: -9%;
    scale: 0.8;
}

.homepage-sectionII .dotted2 {
    position: absolute;
    bottom: -14%;
    left: 37%;

}

.gradient-I {
    position: absolute;
    background: linear-gradient(rgba(84, 60, 174, 1), rgba(49, 24, 119, 1));
    width: 143px;
    height: 620px;
    filter: blur(160px);
    opacity: 0.4;
    left: 81%;
    top: 12%;
    rotate: 50deg;
}

.linear-gradient {
    position: absolute;
    width: 50%;
    z-index: 9;
    height: 490px;
    top: -18%;
    opacity: 0.05;
    filter: blur(5px);
    background: linear-gradient(90deg, #181818 0%, #5238B0 100%);
}

.linear-gradient-II {
    position: absolute;
    width: 50%;
    z-index: 9;
    height: 490px;
    top: -18%;
    opacity: 0.05;
    right: 0%;
    filter: blur(5px);
    background: linear-gradient(-90deg, #181818 0%, #5238B0 100%);
}

.modal-content {
    padding: 22px;
    width: 742px;
    background: linear-gradient(96deg, rgba(255, 255, 255, 0.14) -1.05%, rgba(255, 255, 255, 0.00) 113.38%);
    border: 1px solid white;
    backdrop-filter: blur(3.488299608230591px);
    border-radius: 30px;
    gap: 18px;
}

.modal-dialog {
    width: 742px;
    max-width: 742px;
    margin-top: 200px;
}

.modal-content img {
    border-radius: 30px;
}

.context {
    background-image: url(../../images/popup.png);
    height: 428px;
    border-radius: 23px;
}

.header-image {
    display: flex;
    justify-content: center;
}

.text {
    padding-top: 14px;
    display: flex;
    justify-content: center;
    padding-bottom: 165px;
}

.context h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Trap';
    font-size: 17.009px;
    font-style: normal;
    font-weight: 900;
    line-height: 127.595%;
    width: 460px;
}

.blacktext {
    color: #020203;
    font-family: 'Trap';
    font-size: 17.009px;
    font-style: normal;
    font-weight: 900;
    line-height: 127.595%;
    background: #FFB415;
}

.bottom-logos {
    display: flex;
    justify-content: space-between;
    padding-top: 38px;
}

.bottom-left {
    display: flex;
    width: fit-content;
    padding-left: 15px;
}

.trophy {
    width: 35.529px;
    height: 71.057px;
}

.text-logo-left p {
    color: #FFF;
    text-align: center;
    font-family: 'Trap';
    font-size: 11.478px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    padding-left: 7px;
}

.bottom-right-left {
    padding-top: 1px;
}

.bottom-right {
    display: flex;
    width: fit-content;
    padding-right: 15px;
    padding-top: 20px;
}

.bottom-right a {
    color: #FFF;
    text-align: center;
    font-family: "Inter";
    font-size: 8.346px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
}

.popup-button {
    display: flex;
    align-self: center;
}

.popup-button button {
    display: flex;
    width: 176px;
    color: white;
    height: 40.956px;
    padding: 11.069px 0px;
    justify-content: center;
    align-items: center;
    gap: 11.069px;
    flex-shrink: 0;
    border-radius: 110.692px;
    border: 0.553px solid #212121;
    background: linear-gradient(180deg, #E31E25 0%, #FA3E45 100%);
    box-shadow: 0px 1px 2px 0px rgba(3, 2, 3, 0.10), 0px 3px 3px 0px rgba(3, 2, 3, 0.09), 0px 6px 4px 0px rgba(3, 2, 3, 0.05), 0px 11px 5px 0px rgba(3, 2, 3, 0.01), 0px 18px 5px 0px rgba(3, 2, 3, 0.00);
}

.popup-button button a {
    color: #FFF;
    text-align: center;
    font-family: 'Trap';
    font-size: 15.497px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.799px;
    text-decoration: none;
    letter-spacing: -0.31px;
}

.closebutton {
    top: -13px;
    right: -12px;
    position: absolute;
    cursor: pointer;
}

.text-logo-left {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.modal-content img {
    border-radius: 0;
}

@media (max-width: 1680px) {
    .gradient-I {
        left: 77%;
    }

    .bg {
        top: -13.5%;
    }

    .modal-dialog {
        width: 730px;
        max-width: 730px;
        margin-top: 80px;
    }

    .modal-content {
        width: 692px;
    }
}

@media (max-width: 1440px) {
    .homepage-banner .spacing-p {
        width: 72%;
    }
    .gradient-I {
        left: 74%;
    }
}

@media(max-width: 1366px) {
    .homebanner {
        background: #181818;
        position: relative;
        padding-bottom: 17%;
    }

    .gradient-I {
        left: 73%;
    }

    .homebanner video {
        margin-top: -44%;
    }

    .homepage-banner p {
        font-family: "Trap";
        font-size: 18px;
        font-weight: 300;
        color: #ffff;
        width: 77%;
        margin-top: 2%;
    }

    .homepage-banner .heading-tagline {
        font-family: "Trap";
        font-size: 18px;
        font-weight: 300;
        color: #ffff;
        width: 72%;
        margin-top: 2%;
        margin-bottom: 0;
    }

    .homepage-banner .spacing-p {
        width: 76%;
    }

    .vectorI,
    .vectorII,
    .vectorIII {
        position: absolute;
        height: 17px;
        width: 1px;
        background: white;
        z-index: 999;
        opacity: 1;
        top: 99%;
        animation-delay: 5s;
    }

    .vectorI {
        left: 20%;

        animation: moveup 6s infinite 3s
    }

    .vectorII {
        left: 50%;

        animation: moveup 6s infinite 3.5s
    }

    .vectorIII {
        left: 80%;

        animation: moveup 6s infinite 4s
    }

    @keyframes moveup {
        0% {
            top: 99%;
        }

        100% {
            top: -10%;
        }
    }

    .bg {
        top: -17%;
    }

    .sectionII-bg {
        position: absolute;
        width: 100%;
        top: -18%;
    }

    .linear-gradient {
        position: absolute;
        width: 50%;
        z-index: 9;
        height: 490px;
        top: -36%;
        opacity: 0.05;
        filter: blur(5px);
        background: linear-gradient(90deg, #181818 0%, #5238B0 100%);
    }

    .linear-gradient-II {
        position: absolute;
        width: 50%;
        z-index: 9;
        height: 490px;
        top: -36%;
        opacity: 0.05;
        right: 0%;
        filter: blur(5px);
        background: linear-gradient(-90deg, #181818 0%, #5238B0 100%);
    }

    .modal-dialog {
        width: 730px;
        max-width: 730px;
        margin-top: 30px;
    }
}

@media (max-width: 1280px) {
    .homepage-banner .spacing-p {
        width: 83%;
    }

    .homepage-banner .heading-tagline {
        width: 78%;
    }
}

@media (max-width: 1024px) {
    .scrollup {
        right: 15%;
    }
    .gradient-I {
        left: 63%;
    }
}

@media (max-width:820px) {

    .homebanner video {
        position: absolute;
        width: 184%;
        rotate: 90deg;
        left: -44%;
        opacity: 0.1;
        top: 14%;
    }


    body::before {
        content: "";
        position: fixed;
        top: -1%;
        left: -1%;
        height: 100%;
        opacity: 0.07;
        width: 100%;
        background-image: url(../../images/grid-background.svg);
        background-size: cover;
        z-index: 5;
    }

    .homepage-banner {
        text-align: left;
        padding-left: 32px;
        align-items: baseline;
    }

    .homepage-banner h1,
    .homepage-banner h2 {
        font-size: 31px;
        width: 500px;
    }

    .homepage-banner h2 {
        padding-bottom: 32px;
    }

    .heading-span {
        font-size: 37.667px;
    }

    .gradient-I {
        display: none;
    }

    .homepage-banner p {
        width: 90%;
    }

    .linear-gradient {
        position: absolute;
        width: 100%;
        z-index: 2;
        height: 50%;
        top: -5%;
        opacity: 1;
        filter: blur(5px);
        rotate: 180deg;
        background: linear-gradient(rgba(49, 24, 119, 0.20) 53.89%, rgba(24, 24, 25, 0.00) 112.16%);
    }

    .linear-gradient-II {
        position: absolute;
        width: 100%;
        z-index: 2;
        height: 490px;
        top: 45%;
        opacity: 1;
        right: 0%;
        filter: blur(5px);
        background: linear-gradient(rgba(49, 24, 119, 0.20) 53.89%, rgba(24, 24, 25, 0.00) 112.16%);
    }

    .homepage-banner .spacing-p {
        padding-top: 3%;
    }

    .homepage-banner .heading-tagline {
        width: 88%;
    }

    .scrollup {
        display: none;
    }

    .modal-content {
        padding: 23px 11px;
        width: 329px;
        background: linear-gradient(96deg, rgba(255, 255, 255, 0.14) -1.05%, rgba(255, 255, 255, 0.00) 113.38%);
        border: 1px solid white;
        backdrop-filter: blur(3.488299608230591px);
        border-radius: 30px;
        gap: 18px;
    }

    .modal-dialog {
        width: 329px;
        max-width: 730px;
        margin-top: 74px;
        scale: 1;
        padding-left: 0px;
    }

    .modal-content img {
        border-radius: 30px;
    }

    .context {
        background-image: url(../../images/contextbgmob.webp);
        background-size: contain;
        height: 428px;
        border-radius: 9.6px;
    }

    .header-image {
        display: flex;
        justify-content: center;
    }

    .text {
        padding-top: 180px;
        display: flex;
        justify-content: center;
        padding-bottom: 0;
    }

    .context h2 {
        color: #FFF;
        text-align: center;
        font-family: 'Trap';
        font-size: 17.009px;
        font-style: normal;
        font-weight: 900;
        line-height: 127.595%;
        width: 460px;
    }

    .blacktext {
        color: #020203;
        font-family: 'Trap';
        font-size: 17.009px;
        font-style: normal;
        font-weight: 900;
        line-height: 127.595%;
        background: #FFB415;
    }

    .bottom-logos {
        display: flex;
        justify-content: space-between;
        padding-top: 0;
    }

    .bottom-left {
        display: flex;
        width: fit-content;
        padding-left: 6px;
    }

    .trophy {
        width: 35.529px;
        height: 71.057px;
    }

    .text-logo-left p {
        color: #FFF;
        text-align: center;
        font-family: 'Trap';
        font-size: 5.045px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
        padding-left: 7px;
    }

    .callwiselogo img {
        width: 63.482px;
        height: 15.616px;
    }

    .bottom-right-left {
        padding-top: 1px;
    }

    .bottom-right {
        display: flex;
        width: fit-content;
        padding-right: 10px;
        padding-top: 20px;
    }

    .bottom-right a {
        color: #FFF;
        text-align: center;
        font-family: "Inter";
        font-size: 8.346px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: none;
    }

    .popup-button {
        display: flex;
        align-self: center;
    }

    .popup-button button {
        display: flex;
        width: 176px;
        color: white;
        height: 40.956px;
        padding: 11.069px 0px;
        justify-content: center;
        align-items: center;
        gap: 11.069px;
        flex-shrink: 0;
        border-radius: 110.692px;
        border: 0.553px solid #212121;
        background: linear-gradient(180deg, #E31E25 0%, #FA3E45 100%);
        box-shadow: 0px 1px 2px 0px rgba(3, 2, 3, 0.10), 0px 3px 3px 0px rgba(3, 2, 3, 0.09), 0px 6px 4px 0px rgba(3, 2, 3, 0.05), 0px 11px 5px 0px rgba(3, 2, 3, 0.01), 0px 18px 5px 0px rgba(3, 2, 3, 0.00);
    }

    .popup-button button a {
        color: #FFF;
        text-align: center;
        font-family: 'Trap';
        font-size: 15.497px;
        font-style: normal;
        font-weight: 400;
        line-height: 23.799px;
        text-decoration: none;
        letter-spacing: -0.31px;
    }

    .closebutton {
        top: -13px;
        right: -12px;
        position: absolute;
        cursor: pointer;
    }

    .text-logo-left {
        margin-top: 26px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .modal-content img {
        border-radius: 0;
    }

}

@media (max-width:490px) {
    .homebanner video {
        position: absolute;
        width: 184%;
        rotate: 90deg;
        left: -44%;
        opacity: 0.1;
        top: 14%;
    }


    body::before {
        content: "";
        position: fixed;
        top: -1%;
        left: -1%;
        height: 100%;
        opacity: 0.07;
        width: 100%;
        background-image: url(../../images/grid-background.svg);
        background-size: cover;
        z-index: 5;
    }

    .homepage-banner {
        text-align: left;
        padding-left: 32px;
        align-items: baseline;
    }

    .homepage-banner h1,
    .homepage-banner h2 {
        font-size: 31px;
        width: 299px;
    }

    .homepage-banner h2 {
        padding-bottom: 32px;
    }

    .heading-span {
        font-size: 37.667px;
    }

    .gradient-I {
        display: none;
    }

    .homepage-banner p {
        width: 90%;
    }

    .linear-gradient {
        position: absolute;
        width: 100%;
        z-index: 2;
        height: 50%;
        top: -5%;
        opacity: 1;
        filter: blur(5px);
        rotate: 180deg;
        background: linear-gradient(rgba(49, 24, 119, 0.20) 53.89%, rgba(24, 24, 25, 0.00) 112.16%);
    }

    .linear-gradient-II {
        position: absolute;
        width: 100%;
        z-index: 2;
        height: 490px;
        top: 45%;
        opacity: 1;
        right: 0%;
        filter: blur(5px);
        background: linear-gradient(rgba(49, 24, 119, 0.20) 53.89%, rgba(24, 24, 25, 0.00) 112.16%);
    }

    .homepage-banner .spacing-p {
        padding-top: 8%;
    }

    .scrollup {
        display: none;
    }

    .modal-content {
        padding: 23px 11px;
        width: 329px;
        background: linear-gradient(96deg, rgba(255, 255, 255, 0.14) -1.05%, rgba(255, 255, 255, 0.00) 113.38%);
        border: 1px solid white;
        backdrop-filter: blur(3.488299608230591px);
        border-radius: 30px;
        gap: 18px;
    }

    .modal-dialog {
        width: 329px;
        max-width: 730px;
        margin-top: 74px;
        scale: 1;
        padding-left: 24px;
    }

    .modal-content img {
        border-radius: 30px;
    }

    .context {
        background-image: url(../../images/contextbgmob.webp);
        height: 428px;
        background-size: contain;
        border-radius: 9.6px;
    }

    .header-image {
        display: flex;
        justify-content: center;
    }

    .text {
        padding-top: 180px;
        display: flex;
        justify-content: center;
        padding-bottom: 0;
    }

    .context h2 {
        color: #FFF;
        text-align: center;
        font-family: 'Trap';
        font-size: 17.009px;
        font-style: normal;
        font-weight: 900;
        line-height: 127.595%;
        width: 460px;
    }

    .blacktext {
        color: #020203;
        font-family: 'Trap';
        font-size: 17.009px;
        font-style: normal;
        font-weight: 900;
        line-height: 127.595%;
        background: #FFB415;
    }

    .bottom-logos {
        display: flex;
        justify-content: space-between;
        padding-top: 0;
    }

    .bottom-left {
        display: flex;
        width: fit-content;
        padding-left: 6px;
    }

    .trophy {
        width: 35.529px;
        height: 71.057px;
    }

    .text-logo-left p {
        color: #FFF;
        text-align: center;
        font-family: 'Trap';
        font-size: 5.045px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
        padding-left: 7px;
    }

    .callwiselogo img {
        width: 63.482px;
        height: 15.616px;
    }

    .bottom-right-left {
        padding-top: 1px;
    }

    .bottom-right {
        display: flex;
        width: fit-content;
        padding-right: 10px;
        padding-top: 20px;
    }

    .bottom-right a {
        color: #FFF;
        text-align: center;
        font-family: "Inter";
        font-size: 8.346px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: none;
    }

    .popup-button {
        display: flex;
        align-self: center;
    }

    .popup-button button {
        display: flex;
        width: 176px;
        color: white;
        height: 40.956px;
        padding: 11.069px 0px;
        justify-content: center;
        align-items: center;
        gap: 11.069px;
        flex-shrink: 0;
        border-radius: 110.692px;
        border: 0.553px solid #212121;
        background: linear-gradient(180deg, #E31E25 0%, #FA3E45 100%);
        box-shadow: 0px 1px 2px 0px rgba(3, 2, 3, 0.10), 0px 3px 3px 0px rgba(3, 2, 3, 0.09), 0px 6px 4px 0px rgba(3, 2, 3, 0.05), 0px 11px 5px 0px rgba(3, 2, 3, 0.01), 0px 18px 5px 0px rgba(3, 2, 3, 0.00);
    }

    .popup-button button a {
        color: #FFF;
        text-align: center;
        font-family: 'Trap';
        font-size: 15.497px;
        font-style: normal;
        font-weight: 400;
        line-height: 23.799px;
        text-decoration: none;
        letter-spacing: -0.31px;
    }

    .closebutton {
        top: -13px;
        right: -12px;
        position: absolute;
        cursor: pointer;
    }

    .text-logo-left {
        margin-top: 26px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .modal-content img {
        border-radius: 0;
    }

    .modal-dialog {
        padding-left: 64px;
    }
}

@media (max-width:450px) {

    .homebanner video {
        position: absolute;
        width: 184%;
        rotate: 90deg;
        left: -44%;
        opacity: 0.1;
        top: 14%;
    }


    body::before {
        content: "";
        position: fixed;
        top: -1%;
        left: -1%;
        height: 100%;
        opacity: 0.07;
        width: 100%;
        background-image: url(../../images/grid-background.svg);
        background-size: cover;
        z-index: 5;
    }

    .homepage-banner {
        text-align: left;
        padding-left: 32px;
        align-items: baseline;
    }

    .homepage-banner h1,
    .homepage-banner h2 {
        font-size: 31px;
        width: 299px;
    }

    .homepage-banner h2 {
        padding-bottom: 32px;
    }

    .heading-span {
        font-size: 37.667px;
    }

    .gradient-I {
        display: none;
    }

    .homepage-banner p {
        width: 90%;
    }

    .linear-gradient {
        position: absolute;
        width: 100%;
        z-index: 2;
        height: 50%;
        top: -5%;
        opacity: 1;
        filter: blur(5px);
        rotate: 180deg;
        background: linear-gradient(rgba(49, 24, 119, 0.20) 53.89%, rgba(24, 24, 25, 0.00) 112.16%);
    }

    .linear-gradient-II {
        position: absolute;
        width: 100%;
        z-index: 2;
        height: 490px;
        top: 45%;
        opacity: 1;
        right: 0%;
        filter: blur(5px);
        background: linear-gradient(rgba(49, 24, 119, 0.20) 53.89%, rgba(24, 24, 25, 0.00) 112.16%);
    }

    .homepage-banner .spacing-p {
        padding-top: 8%;
    }

    .scrollup {
        display: none;
    }

    .modal-content {
        padding: 23px 11px;
        width: 329px;
        background: linear-gradient(96deg, rgba(255, 255, 255, 0.14) -1.05%, rgba(255, 255, 255, 0.00) 113.38%);
        border: 1px solid white;
        backdrop-filter: blur(3.488299608230591px);
        border-radius: 30px;
        gap: 18px;
    }

    .modal-dialog {
        width: 329px;
        max-width: 730px;
        margin-top: 74px;
        scale: 1;
        padding-left: 24px;
    }

    .modal-content img {
        border-radius: 30px;
    }

    .context {
        background-image: url(../../images/contextbgmob.webp);
        height: 428px;
        background-size: contain;
        border-radius: 9.6px;
    }

    .header-image {
        display: flex;
        justify-content: center;
    }

    .text {
        padding-top: 180px;
        display: flex;
        justify-content: center;
        padding-bottom: 0;
    }

    .context h2 {
        color: #FFF;
        text-align: center;
        font-family: 'Trap';
        font-size: 17.009px;
        font-style: normal;
        font-weight: 900;
        line-height: 127.595%;
        width: 460px;
    }

    .blacktext {
        color: #020203;
        font-family: 'Trap';
        font-size: 17.009px;
        font-style: normal;
        font-weight: 900;
        line-height: 127.595%;
        background: #FFB415;
    }

    .bottom-logos {
        display: flex;
        justify-content: space-between;
        padding-top: 0;
    }

    .bottom-left {
        display: flex;
        width: fit-content;
        padding-left: 6px;
    }

    .trophy {
        width: 35.529px;
        height: 71.057px;
    }

    .text-logo-left p {
        color: #FFF;
        text-align: center;
        font-family: 'Trap';
        font-size: 5.045px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
        padding-left: 7px;
    }

    .callwiselogo img {
        width: 63.482px;
        height: 15.616px;
    }

    .bottom-right-left {
        padding-top: 1px;
    }

    .bottom-right {
        display: flex;
        width: fit-content;
        padding-right: 10px;
        padding-top: 20px;
    }

    .bottom-right a {
        color: #FFF;
        text-align: center;
        font-family: "Inter";
        font-size: 8.346px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: none;
    }

    .popup-button {
        display: flex;
        align-self: center;
    }

    .popup-button button {
        display: flex;
        width: 176px;
        color: white;
        height: 40.956px;
        padding: 11.069px 0px;
        justify-content: center;
        align-items: center;
        gap: 11.069px;
        flex-shrink: 0;
        border-radius: 110.692px;
        border: 0.553px solid #212121;
        background: linear-gradient(180deg, #E31E25 0%, #FA3E45 100%);
        box-shadow: 0px 1px 2px 0px rgba(3, 2, 3, 0.10), 0px 3px 3px 0px rgba(3, 2, 3, 0.09), 0px 6px 4px 0px rgba(3, 2, 3, 0.05), 0px 11px 5px 0px rgba(3, 2, 3, 0.01), 0px 18px 5px 0px rgba(3, 2, 3, 0.00);
    }

    .popup-button button a {
        color: #FFF;
        text-align: center;
        font-family: 'Trap';
        font-size: 15.497px;
        font-style: normal;
        font-weight: 400;
        line-height: 23.799px;
        text-decoration: none;
        letter-spacing: -0.31px;
    }

    .closebutton {
        top: -13px;
        right: -12px;
        position: absolute;
        cursor: pointer;
    }

    .text-logo-left {
        margin-top: 26px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .modal-content img {
        border-radius: 0;
    }

    .modal-dialog {
        padding-left: 40px;
    }
}

@media (max-width:390px) {
    .modal-content {
        padding: 23px 11px;
        width: 329px;
        background: linear-gradient(96deg, rgba(255, 255, 255, 0.14) -1.05%, rgba(255, 255, 255, 0.00) 113.38%);
        border: 1px solid white;
        backdrop-filter: blur(3.488299608230591px);
        border-radius: 30px;
        gap: 18px;
    }

    .modal-dialog {
        width: 329px;
        max-width: 730px;
        margin-top: 74px;
        scale: 1;
        padding-left: 24px;
    }

    .modal-content img {
        border-radius: 30px;
    }

    .context {
        background-image: url(../../images/contextbgmob.webp);
        height: 428px;
        background-size: contain;
        border-radius: 9.6px;
    }

    .header-image {
        display: flex;
        justify-content: center;
    }

    .text {
        padding-top: 180px;
        display: flex;
        justify-content: center;
        padding-bottom: 0;
    }

    .context h2 {
        color: #FFF;
        text-align: center;
        font-family: 'Trap';
        font-size: 17.009px;
        font-style: normal;
        font-weight: 900;
        line-height: 127.595%;
        width: 460px;
    }

    .blacktext {
        color: #020203;
        font-family: 'Trap';
        font-size: 17.009px;
        font-style: normal;
        font-weight: 900;
        line-height: 127.595%;
        background: #FFB415;
    }

    .bottom-logos {
        display: flex;
        justify-content: space-between;
        padding-top: 0;
    }

    .bottom-left {
        display: flex;
        width: fit-content;
        padding-left: 6px;
    }

    .trophy {
        width: 35.529px;
        height: 71.057px;
    }

    .text-logo-left p {
        color: #FFF;
        text-align: center;
        font-family: 'Trap';
        font-size: 5.045px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
        padding-left: 7px;
    }

    .callwiselogo img {
        width: 63.482px;
        height: 15.616px;
    }

    .bottom-right-left {
        padding-top: 1px;
    }

    .bottom-right {
        display: flex;
        width: fit-content;
        padding-right: 10px;
        padding-top: 20px;
    }

    .bottom-right a {
        color: #FFF;
        text-align: center;
        font-family: "Inter";
        font-size: 8.346px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: none;
    }

    .popup-button {
        display: flex;
        align-self: center;
    }

    .popup-button button {
        display: flex;
        width: 176px;
        color: white;
        height: 40.956px;
        padding: 11.069px 0px;
        justify-content: center;
        align-items: center;
        gap: 11.069px;
        flex-shrink: 0;
        border-radius: 110.692px;
        border: 0.553px solid #212121;
        background: linear-gradient(180deg, #E31E25 0%, #FA3E45 100%);
        box-shadow: 0px 1px 2px 0px rgba(3, 2, 3, 0.10), 0px 3px 3px 0px rgba(3, 2, 3, 0.09), 0px 6px 4px 0px rgba(3, 2, 3, 0.05), 0px 11px 5px 0px rgba(3, 2, 3, 0.01), 0px 18px 5px 0px rgba(3, 2, 3, 0.00);
    }

    .popup-button button a {
        color: #FFF;
        text-align: center;
        font-family: 'Trap';
        font-size: 15.497px;
        font-style: normal;
        font-weight: 400;
        line-height: 23.799px;
        text-decoration: none;
        letter-spacing: -0.31px;
    }

    .closebutton {
        top: -13px;
        right: -12px;
        position: absolute;
        cursor: pointer;
    }

    .text-logo-left {
        margin-top: 26px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .modal-content img {
        border-radius: 0;
    }
}

@media (max-width:360px) {
    .modal-dialog {
        padding-left: 9px;
    }

    .linear-gradient-II {
        height: 610px;
    }
}

@media (max-width:280px) {
    .homepage-banner {
        padding-left: 10px;
    }

    .homepage-banner h1,
    .homepage-banner h2 {
        font-size: 28px;
        width: 255px;
    }

    .heading-span {
        font-size: 34.667px;
    }

    .homepage-banner .heading-tagline {
        width: 95%;
    }

    .homepage-banner .spacing-p {
        width: 95%;
    }

    .modal-dialog {
        padding-left: 2px;
    }

    .modal-content {
        padding: 23px 6px;
        width: 260px;
        background: linear-gradient(96deg, rgba(255, 255, 255, 0.14) -1.05%, rgba(255, 255, 255, 0.00) 113.38%);
        border: 1px solid white;
        -webkit-backdrop-filter: blur(3.488299608230591px);
        backdrop-filter: blur(3.488299608230591px);
        border-radius: 30px;
        gap: 18px;
    }

    .text {
        padding-top: 156px;
        display: flex;
        justify-content: center;
        padding-bottom: 0;
    }

    .bottom-right a {
        font-size: 7.5px;
    }

    .bottom-right {
        padding-right: 0px;
    }
}